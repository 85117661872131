.multiselect {
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 100px);
  flex-wrap: wrap;
  background-color: white;
  margin-bottom: 4em;
  z-index: 1;
  right: 0;
  top: 0em;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px rgba(50, 50, 71, 0.08);
}

.multiselect.sidebarWide {
  width: calc(100% - 240px);
}

.multiselect.multiSelectBar {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.multiselect.scrolled {
  margin-top: 0px;
}

.download_icon {
  max-width: 100%;
  width: 24px;
}

.share_div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1em;
}

.height_40 {
  height: 40px;
}

.select_text {
  color: #797979;
  font-size: 11px;
  margin-top: 0.5em;
}

.modal_header_div_select {
  display: flex;
  align-items: center;
  justify-content: center;
}

.width_25 {
  width: 25px;
  margin-right: 0.5em;
}

.modal_subheader_text_select {
  text-align: center;
  font-weight: 600;
}

.modal_selection_text {
  margin-top: 3em;
  font-weight: 600;
}

.modal_selection_links {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.modal_selection_links_text {
  border: 1px solid #8f4fff;
  padding: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 70%;
  overflow-wrap: anywhere;
}

.modal_selection_links_button {
  font-weight: 600;
  background-color: #8f4fff;
  color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 30%;
}

.modal_selection_links_copied {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  bottom: 10px;
}

.margin_left_1 {
  margin-left: 1em;
}

.upload-section {
  margin-top: 0.5em;
}

.upload-div {
  display: flex;
  align-items: center;
}

.upload-p {
  font-weight: 600;
  margin-left: 0.5em;
}

.upload-i {
  font-size: 13px;
  margin-top: 0.5em;
}

.zoom_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.icon_button {
  padding: 8px;
  color: white;
}

.icon_button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.container_top {
  margin-top: 3em;
}

.container_bottom {
  margin-bottom: 3em;
}

.deleted_session {
  font-size: 20px;
  font-weight: 600;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
}

.flex_container {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.mini-panel-container {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 10px;
  margin-top: 2em;
}

.mini-panel-img {
  margin-right: 2em;
}

.mini-panel-header {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.mini-panel-link {
  color: #8f4fff;
  text-decoration: underline;
}

.right_0 {
  right: 0;
  left: auto;
}

.top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  position: absolute;
  width: 99%;
  z-index: 2;
  background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.45));
}

.white_button {
  color: white;
  margin: 0;
}

.flex_center {
  color: white;
  display: flex;
  align-items: center;
}

.visibility_icon {
  margin-right: 0.3em;
  height: 15px;
  max-width: 100%;
}

.download_button_file {
  color: white;
  margin: 0;
  padding: 0;
}

.flex_column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.prev_btn {
  padding: 0;
  height: 24px;
  z-index: 3;
}

.auto_margin {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.auto_margin_img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100vh;
}

.file_counter {
  text-align: center;
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 2em;
  padding: 15px;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1));
  border-radius: 10px;
}

.close_icon {
  color: black;
  margin: 0;
  margin-right: 2em;
}

.font_weight_600 {
  font-weight: 600;
}

.flex_auto_margin {
  display: flex;
  margin-left: auto;
}

.flex_center_column {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1em;
}

.img_height_40 {
  height: 40px;
}

.save_to_device {
  color: #797979;
  font-size: 11px;
  margin-top: 0.5em;
}
