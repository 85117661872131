body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3d3838;
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Red Hat Display", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202026;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f4fff;
  border-radius: 10px;
}

#text {
  max-width: 30px;
}

button {
  font-family: "Red Hat Display", sans-serif;
}

select {
  font-family: "Red Hat Display", sans-serif;
}

option {
  font-family: "Red Hat Display", sans-serif;
}

.margin {
  padding: 40px 88px;
}

.rdrMonthAndYearWrapper {
  position: absolute;
  z-index: 20;
  top: -2em;
}

.rdrCalendarWrapper {
  position: absolute;
}

.table_container {
  overflow-x: auto;
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 1023px) {
  .margin {
    padding: 40px;
  }
}

@media (max-width: 655px) {
  .margin {
    padding: 16px;
  }
}
@media (max-width: 570px) {
  .rdrMonthAndYearWrapper {
    top: -2em;
    background-color: white;
  }
  /* .name div {
    top: -1em;
  } */
}

@media (max-width: 500px) {
  .rdrMonthAndYearWrapper {
    top: -2em;
  }
}

@media (max-width: 360px) {
  .margin {
    padding: 8px;
  }
}

.sidebar {
  border-radius: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 55px;
}

.sidebar.active {
  font-weight: 700;
  color: #8f4fff;
  background: #f1e7ff;
}

header {
  transition: all 0.2s ease-in-out;
}

.version_text {
  color: #999999;
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
  font-size: 13px;
}

.transform-component-module_wrapper__SPB86 {
  overflow: visible !important;
}
