.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: white;
  padding: 2rem;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-upload-style {
  margin-right: 1em;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 24px;
  padding: 0px 20px;
  color: initial;
}

.button-disabled {
  color: #999999;
}

.flex-center {
  display: flex;
  align-items: center;
}

.icon-import-button {
  padding: 10px;
}

.icon-import-image {
  width: 24px;
}

.import-text {
  font-size: 16px;
}

.button-business-style {
  margin-right: 1em;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 24px;
  padding: 0px 20px;
  color: #999999;
  background-color: #efefef;
}

.modal-csv-data {
  border-radius: 30px;
  margin: 0;
}

.dialog-content {
  background-color: #fff;
  padding: 0;
}

.box-style {
  overflow: auto;
}

.csv-name {
  font-weight: 700;
  text-align: center;
  box-shadow: 0px 2px 4px 0px #32324714;
  padding: 20px;
}

.table-container-csv {
  border-radius: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  min-width: 1000px;
}

.flex-end-container {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.button-close-csv {
  width: fit-content;
  background-color: #8f4fff;
  padding: 10px 40px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: white;
  border: none;
  cursor: pointer;
}

.multi-user-access {
  font-weight: 700;
  text-align: center;
  box-shadow: 0px 2px 4px 0px #32324714;
  padding: 20px;
}

.grid-item {
  order: 2;
  background-color: #9747ff;
  padding: 20px;
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-md {
  order: 1;
}

.text-left {
  text-align: left;
  width: 90%;
  margin: 0 auto;
}

.ReactVirtualized__Table__headerRow {
  position: sticky;
  top: 0;
  background-color: #e2e2e2;
  z-index: 1;
  padding: 10px 0px;
  border-bottom: 1px solid var(--divider, #0000001f);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
