.login {
  max-width: 375px;
  width: 375px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 90vh;
  background: #fdfdfd;
  box-shadow: 0px 4px 9px rgba(58, 92, 157, 0.15);
}

@media (max-width: 375px) {
  .login {
    width: 100%;
    max-width: none;
    height: 100vh;
  }
}

.verified_email {
  color: #8f4fff;
  font-weight: 600;
}

.login-button {
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  padding: 10px 25px;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  background: #8f4fff;
  box-shadow: 0px 2px 8px rgb(58 92 157 / 14%);
  border-radius: 24px;
}

.email_button {
  background: #8f4fff;
  box-shadow: 0px 2px 8px rgb(58 92 157 / 14%);
  border-radius: 24px;
  border: none;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: 700;
}

.email_button:hover {
  transform: scale(1.01);
  transition: all 0.5s;
  cursor: pointer;
}

.login-button:hover {
  transform: scale(1.01);
  transition: all 0.5s;
  cursor: pointer;
}

.bg_login {
  position: absolute;
  z-index: 0;
  height: 90vh;
}

.bg_login img {
  position: absolute;
  z-index: 0;
  height: 90vh;
  width: 320px;
}

.login_content {
  z-index: 1;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: -webkit-fill-available;
}

.login_main {
  height: 100vh;
  display: flex;
  align-items: center;
}

.email-input {
  background: #ffffff;
  padding: 0px 0px 0px 15px;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px rgb(58 92 157 / 14%);
  border-radius: 6px;
  font-size: 16px;
  height: 40px;
  width: 94%;
  margin-bottom: 1em;
  font-family: "Red Hat Display", sans-serif;
}

.logo_login {
  width: 200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.or {
  display: flex;
  color: #999999;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.google {
  background: #4286f5;
  box-shadow: 0px 2px 8px rgb(58 92 157 / 14%);
  border-radius: 24px;
  border: none;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 16px;
  font-weight: 700;
}

.apple {
  background: #000000;
  box-shadow: 0px 2px 8px rgb(58 92 157 / 14%);
  border-radius: 24px;
  border: none;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin-top: 1em;
  font-size: 16px;
  font-weight: 700;
}

.google:hover {
  transform: scale(1.01);
  transition: all 0.5s;
  cursor: pointer;
}

.apple:hover {
  transform: scale(1.01);
  transition: all 0.5s;
  cursor: pointer;
}

.link_forgot a {
  color: #797979;
  text-decoration: none;
}

.link_forgot {
  text-align: center;
}

.link_forgot a:hover {
  color: #8f4fff;
  transition: all 0.3s;
}

.clean_input {
  border: none;
  outline: none;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  width: fit-content;
}

.clean_input:hover {
  background-color: #f5f5f5;
}

.clean_input:focus {
  background-color: #fff;
  border: 1px solid #8f4fff;
}

.account_data {
  font-size: 16px;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  border-radius: 10px;
  overflow-wrap: break-word;
}

.account_data:hover {
  background-color: #f5f5f5;
}

.profile_flex {
  display: flex;
  justify-content: space-between;
}

.profile_flex div {
  width: 45%;
}

li::marker {
  color: #8f4fff;
}

.reset_password_h2 {
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
  color: #3d3838;
}

.login_h2 {
  text-align: center;
  margin-bottom: 0em;
  font-weight: 600;
  color: #3d3838;
}

.login_icon {
  margin-top: 0.2em;
  margin-right: 1em;
  height: 32px;
  width: 32px;
}

.sign_up_div {
  display: flex;
  margin: 1em 0;
  color: #999999;
}

.hr_sign_up {
  width: 40%;
  background-color: #d9d9d9;
  border: none;
  height: 1px;
}

.connect_a {
  color: #8f4fff;
  text-align: center;
  font-weight: 600;
  margin-top: 3em;
}

.password_h2 {
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
  color: #3d3838;
}

.no_account_div {
  margin-top: 1em;
  text-align: center;
  color: #797979;
}

.justify_content_initial {
  justify-content: initial;
}

.personal_info_screen {
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  padding: 20px;
  text-align: center;
  font-weight: 600;
}

.margin_top_0 {
  margin-top: 0;
}

.text_align_center {
  text-align: center;
}

.padding_10 {
  padding: 10px;
}

.full_name_div {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

.full_name_label {
  background-color: #fdfdfd;
  padding: 30px 0px;
}

.select_country {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  background-color: #fdfdfd;
  color: #333;
  margin-top: 1em;
}

.cancel_button {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.button_no_style.update_personal_info {
  color: #8f4fff;
  font-weight: 600;
}

.update_personal_info {
  color: #8f4fff;
  font-weight: 600;
}

.checkbox_info {
  display: flex;
  margin-top: 1em;
  justify-content: space-around;
}

.checkbox_text {
  text-align: left;
  width: 80%;
}

.font_weight_600 {
  font-weight: 600;
}

.error_personal_info {
  margin-top: 0.5em;
  text-align: center;
  color: red;
}

.select_country_div {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

.sign_up_h2 {
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
  color: #3d3838;
}

.display_justify {
  display: flex;
  justify-content: center;
}

.have_account_div {
  margin-top: 3em;
  text-align: center;
  color: #797979;
}

.purple_text {
  color: #8f4fff;
}

.verification_box {
  background-color: #fdfdfd;
  padding: 0px 0px;
}

.verification_button {
  padding: 0;
  margin-top: 0em;
  margin-bottom: 1em;
}

.verified_button {
  color: #8f4fff;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 600px) {
  .profile_flex {
    flex-direction: column;
  }

  .profile_flex div {
    width: 100%;
  }
}
