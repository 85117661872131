.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.mySlides {
  display: none;
}

.description-wrap {
  word-wrap: break-word;
  overflow-y: scroll;
  /* max-height: calc(100px - 90px); */
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  border-radius: 10px;
  /* min-height: 50px; */
  /* width: 92%; */
  padding: 15px;
  margin: 0;
}

.description-wrap::-webkit-scrollbar {
  width: 5px;
}

.description-wrap::-webkit-scrollbar-track {
  background: #fff;
}

.description-wrap::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.gallery_overlay {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.gallery img {
  /* width: 80%; */
  object-fit: contain;
  /* max-height: 85vh; */
}

.gallery video {
  width: 100%;
  object-fit: contain;
  height: 100vh;
}

.gallery_view {
  display: flex;
  width: 98%;
  left: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  align-items: center;
  justify-content: space-between;
}

.os_od {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

@media (max-width: 430px) {
  .os_od {
    position: relative;
    transform: none;
    left: 0;
  }
}

.hover_icon {
  background-image: url(../images/gallery/circle.svg);
  height: 26px;
  width: 26px;
}

.hover_icon:hover {
  background-image: url(../images/gallery/circle_active.svg);
  height: 24px;
  width: 24px;
}

.active_hover_icon {
  background-image: url(../images/gallery/Select.svg);
  height: 24px;
  width: 24px;
}

.progress-bar-container {
  position: relative;
  width: 150px;
}

.progress-bar {
  width: 100%;
  border-color: none;
}

.progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

progress::-moz-progress-bar {
  background-color: #6b6b6b;
  border-radius: 10px;
}

progress::-moz-progress-value {
  background-color: #8f4fff;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background: #8f4fff;
  border-radius: 10px;
}

progress::-webkit-progress-bar {
  background: #6b6b6b;
  border-radius: 10px;
}

progress {
  color: #8f4fff;
  border-radius: 10px;
}

.pause_button {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pause_button:hover {
  cursor: pointer;
}

.pause_button img {
  position: absolute;
  height: 80px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.videos_page:hover {
  cursor: pointer;
}
input[type="checkbox"] {
  position: absolute;
  left: 0.55em;
  top: 0.6em;
  opacity: 0;
  z-index: 1;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"] + label {
  padding-left: 25px;
  cursor: pointer;
  position: absolute;
  left: 0.7em;
  top: 0.7em;
  display: none;
}

input[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 20px;
  opacity: 1;
}

#myCheckbox {
  width: 24px;
  height: 24px;
}

input[type="checkbox"]:checked + label::before {
  background-color: #8f4fff;
  border-color: #8f4fff;
}

input[type="checkbox"] + label::after {
  content: "\2713";
  position: absolute;
  top: 2px;
  left: 7px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  opacity: 0;
}

input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.hover_over {
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.myimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000 0%, rgba(217, 217, 217, 0) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.image-container:hover .overlay,
.image-container input[type="checkbox"]:checked + label {
  opacity: 1;
}

.image-container:hover input[type="checkbox"] + label {
  display: block;
}

input[type="checkbox"]:checked + label {
  display: block;
}
