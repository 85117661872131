.current_password_div {
  background-color: #fdfdfd;
  padding: 30px 20px;
}

.current_password_label {
  display: flex;
  flex-direction: column;
}

.new_password_div {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.cancel_button {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.font_size_16 {
  font-size: 16px;
}

.change_password_button {
  font-weight: 600;
  color: #8f4fff;
  font-size: 16px;
}

.success_password {
  font-weight: 600;
  text-align: center;
  margin-top: 2em;
}

.margin_top_2 {
  margin-top: 2em;
}

.my_profile_text {
  font-weight: 700;
  font-size: 20px;
}

.company_info_div {
  background-color: #e6d8ff;
  border-radius: 10px;
}

.company_info_text {
  margin-top: 2em;
  font-weight: 600;
  padding: 20px 20px 10px 20px;
}

.hr_style {
  border: 1px solid white;
}

.account_info_div {
  margin-top: 2em;
  font-weight: 600;
}

.hr_account {
  background-color: #d9d9d9;
}

.width_100 {
  width: 100%;
}

.padding_account {
  padding: 20px 0px;
}

/* .profile_flex {
  display: flex;
  align-items: center;
} */

.img_style {
  width: 24px;
  margin-right: 1em;
}

.font_weight_700 {
  font-weight: 700;
}

.font_weight_600 {
  font-weight: 600;
}

.name_div {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  margin-top: 1em;
}

.full_name_padding {
  background-color: #fdfdfd;
  padding: 30px 0px;
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
}

.update_button_color {
  color: #8f4fff;
  font-weight: 600;
}

.error_name {
  margin-top: 0.5em;
  text-align: center;
  color: red;
}

.padding_country {
  padding: 20px 0px;
}

.image_country {
  width: 24px;
  margin-right: 0.5em;
}

.user_country_div {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(58, 92, 157, 0.14);
  margin-top: 1em;
}

.country_div {
  background-color: #fdfdfd;
  padding: 30px 0px;
}

.select_country {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  background-color: #fdfdfd;
  color: #333;
  margin-top: 1em;
}

.user_email {
  padding: 10px 20px;
  overflow-wrap: break-word;
  font-size: 16px;
}

.margin_left_05 {
  margin-left: 0.5em;
}

.change_pass_div {
  margin-top: 2.5em;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.margin_right_05 {
  margin-right: 0.5em;
}
