.full_width {
  width: 100%;
}

.sidebar-centered {
  margin-top: 1em;
  justify-content: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
  padding: 10px 20px;
}

.logo-image {
  width: 110px;
}

.add_margin_left {
  margin-left: 1em;
}

.play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  fill: #fff;
  filter: drop-shadow(0 0 1px #333);
}

.thumbnail_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.flex-center-bg {
  background-color: #e6d8ff;
  display: flex;
  align-items: center;
  height: 40px;
}

.custom-hr {
  background-color: #d9d9d9;
  width: 100%;
  height: 2px;
  border: none;
  margin: 0;
}

.flex-space-between {
  padding: 8px 0px 8px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bold-text {
  font-weight: 700;
}

.flex-center {
  display: flex;
  align-items: center;
}

.fixed-div {
  position: fixed;
  bottom: 0;
  right: 90px;
  z-index: 1000;
  background-color: white;
  width: 370px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media (max-width: 500px) {
  .fixed-div {
    right: 10px;
    width: 310px;
  }
}

.border-div {
  border: 1px solid #3a5c9d24;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.flex-wrap-center {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
}

.scrollable-div {
  overflow-y: scroll;
  max-height: calc(300px - 90px);
}

.relative-inline-block {
  position: relative;
  display: inline-block;
}

.fixed-width-img {
  width: 38px;
}

.link-margin-top {
  margin-top: 1em;
}

.dark-hr {
  background-color: #505050;
  width: 90%;
  height: 2px;
  border: none;
}

.button-margin-padding {
  margin-bottom: 1em;
  padding: 0;
}

.fixed-dimension-img {
  width: 50px;
  height: 50px;
}

.fixed-dimension-img-40 {
  width: 40px;
  height: 40px;
}

.button-margin-center {
  margin-bottom: 2em;
  justify-content: center;
}

.fixed-width-img-40 {
  width: 40px;
}

.padding-10-font-15 {
  padding: 10px;
  font-size: 15px;
}

.padding-left-25 {
  padding-left: 25px;
}

.margin-right-left-1em {
  margin-right: 1em;
  margin-left: 1em;
}

.font-13-color-dark {
  font-size: 13px;
  color: #3d3838;
}

.margin-left-auto {
  margin-left: auto;
}

.fixed-width-img-24 {
  width: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
